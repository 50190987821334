import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Slider from './Slider'
import useMatchMedia from '../utils/useMatchMedia'

const Wrapper = styled.div``

const Canvas = styled.div``

const Columns = styled.div`
  display: flex;
  margin-left: -20px;
`

const Column = styled.div`
  padding-left: 20px;
  width: 33.333333%;
`

const officeImagesQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "office" } }) {
      edges {
        node {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

function renderSlides(images) {
  return images.map(image => <Img fluid={image.childImageSharp.fluid} />)
}

const Gallery = () => {
  const showSlider = useMatchMedia('(max-width: 680px)')
  const data = useStaticQuery(officeImagesQuery)

  const images = data.allFile.edges.map(({ node }) => {
    return node
  })

  return (
    <Wrapper>
      <Canvas>
        {showSlider ? (
          <Slider slides={renderSlides(images)} />
        ) : (
          <Columns>
            {images.map(image => (
              <Column key={image.childImageSharp.fluid.src}>
                <Img fluid={image.childImageSharp.fluid} />
              </Column>
            ))}
          </Columns>
        )}
      </Canvas>
    </Wrapper>
  )
}

export default Gallery
