import React from 'react'
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Text from '../components/Text'
import Gallery from '../components/Gallery'
import Spacer from '../components/Spacer'

const AboutUsPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'about.title' })} />
      <Content intro>
        <Title top={intl.formatMessage({ id: 'about.title' })}>
          <FormattedMessage id="about.headline" />
        </Title>
        <Text align="center">
          <FormattedHTMLMessage id="about.content" />
        </Text>
        <Spacer size="small" />
        <Gallery />
        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default AboutUsPage

export const i18n = {
  en: '/about-us',
  de: '/ueber-uns',
}
