import { useState, useEffect } from 'react'

export default function useMatchMedia(query) {
  const [match, setMatch] = useState(false)

  useEffect(() => {
    const mmq = window.matchMedia(query)
    const changeHandler = () => {
      setMatch(mmq.matches)
    }

    changeHandler()

    // mmq.addEventListener('change', changeHandler) // not supported in all browsers
    mmq.addListener(changeHandler)

    return () => mmq.removeListener(changeHandler)
  }, [])

  return match
}
